'use client'
import cx from 'clsx'
import { Fredoka } from 'next/font/google'
import logger from '@ignition/library/lib/logger'
import '../styles/tailwind.css'
import '../styles/app.css'
import ErrorPage from './error'

const fredoka = Fredoka({
  weight: ['400', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-fredoka',
})

export default function GlobalError({ error }: { error: Error & { digest?: string }; reset: () => void }) {
  logger.error(error)
  return (
    <html>
      <body className={cx(fredoka.variable, 'font-sans bg-background')}>
        <ErrorPage />
      </body>
    </html>
  )
}
